<template>
  <div class="h-full">
    <!-- Top Action: Navigate to Marketplace -->
    <div class="pa-5">
      <v-list-item
        link
        :to="menuItemTopAction.route"
      >
        <v-btn
          outlined
          block
          color="primary"
        >
          <v-icon size="22">
            {{ menuItemTopAction.icon }}
          </v-icon>
          <span class="ml-2">{{ menuItemTopAction.title }}</span>
        </v-btn>
      </v-list-item>
    </div>

    <!-- Scrollable Area -->
    <perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="ps-contact-request-left-sidebar"
    >
      <v-list
        dense
        flat
      >
        <!-- :to="filter.route" -->
        <!-- exact-active-class="primary--text v-list-item--exact-active" -->
        <v-list-item
          v-for="filter in contactRequestFilters"
          :key="filter.title"
          :class="filter.route && isDynamicRoutePathActive(filter.route) ? 'v-list-item--exact-active' : ''"
          @click="redirectWithReload(filter.route)"
        >
          <v-list-item-icon>
            <v-icon size="20">
              {{ filter.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-base">
              {{ filter.title }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action
            v-if="contactRequestsMeta[filter.title.toLowerCase()]"
            class="ma-0"
          >
            <v-badge
              :color="filter.color"
              inline
              :content="contactRequestsMeta[filter.title.toLowerCase()]"
              class="number-badge v-badge-light-bg"
              :class="`${filter.color}--text`"
            >
            </v-badge>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <p class="text-xs text--disabled mt-8 mx-5 mb-1">
        LABELS
      </p>
      <v-list
        dense
        flat
      >
        <!-- :to="label.route" -->
        <!-- exact-active-class="primary--text v-list-item--exact-active" -->
        <v-list-item
          v-for="label in contactRequestLabels"
          :key="label.title"
          :class="label.route && isDynamicRoutePathActive(label.route) ? 'primary--text v-list-item--exact-active' : ''"
          @click="redirectWithReload(label.route)"
        >
          <v-list-item-icon>
            <v-badge
              :color="label.color"
              dot
              inline
              class="align-self-center label-dot"
            ></v-badge>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-base">
              {{ label.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </perfect-scrollbar>
  </div>
</template>

<script>
import {
  mdiSendCheckOutline, mdiSendOutline, mdiSquareEditOutline, mdiStarOutline,
} from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

import { isDynamicRoutePathActive, redirectWithReload } from '@core/utils'
import useRouterPath from '@/router/useRouterPath'

export default {
  components: {
    // 3rd Party
    PerfectScrollbar,
  },
  props: {
    contactRequestsMeta: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { menuItemMarketplace: menuItemTopAction } = useRouterPath()

    const contactRequestFilters = [
      // { title: 'Published', icon: mdiSendOutline, to: { name: 'apps-contact-request-folder' } },
      {
        title: 'Published',
        color: 'success',
        icon: mdiSendOutline,
        route: { name: 'apps-contact-request-folder', params: { folder: 'published' } },
      },
      {
        title: 'Draft',
        color: 'warning',
        icon: mdiSquareEditOutline,
        route: { name: 'apps-contact-request-folder', params: { folder: 'draft' } },
      },
      {
        title: 'Finished',
        color: 'primary',
        icon: mdiSendCheckOutline,
        route: { name: 'apps-contact-request-folder', params: { folder: 'finished' } },
      },
      {
        title: '',
        color: '',
        icon: '',
        route: undefined, // { name: '' },
      },
      {
        title: 'Starred',
        color: 'warning',
        icon: mdiStarOutline,
        route: { name: 'apps-contact-request-folder', params: { folder: 'starred' } },
      },

      // {
      //   title: 'Trash',
      //   color: 'error',
      //   icon: mdiTrashCanOutline,
      //   route: { name: 'apps-contact-request-folder', params: { folder: 'trash' } },
      // },
    ]

    const contactRequestLabels = [
      {
        title: 'Personal',
        color: 'success',
        route: { name: 'apps-contact-request-label', params: { label: 'personal' } },
      },
      {
        title: 'Company',
        color: 'primary',
        route: { name: 'apps-contact-request-label', params: { label: 'company' } },
      },
      {
        title: 'Important',
        color: 'warning',
        route: { name: 'apps-contact-request-label', params: { label: 'important' } },
      },
      { title: 'Private', color: 'error', route: { name: 'apps-contact-request-label', params: { label: 'private' } } },
    ]

    // Perfect Scrollbar
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      wheelSpeed: 0.7,
    }

    return {
      menuItemTopAction,

      // Filter & Labels
      contactRequestFilters,
      contactRequestLabels,

      // Perfect Scrollbar
      perfectScrollbarSettings,

      // Router
      isDynamicRoutePathActive,
      redirectWithReload,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.ps-contact-request-left-sidebar {
  // Si indicamos la altura exacta no hay scroll
  height: calc(100% - 88px); // 78 = 20 + 48 + 20 (48-Botón y 20x2 de padding)
  // height: calc(100%);

  .v-list--dense .v-list-item {
    height: 38px;
    min-height: 38px;
    border-left: 8px solid transparent;

    &.v-list-item--exact-active {
      border-color: var(--v-primary-base);
    }

    .v-list-item__icon {
      align-items: center;
    }

    // label dot
    .label-dot {
      .v-badge__badge {
        width: 12px !important;
        height: 12px !important;
        border-radius: 10px;
      }
    }
  }
}

.contact-request-compose-dialog {
  align-self: flex-end !important;
}

@include app-elevation(contact-request-compose-dialog, 16);
</style>
