<template>
  <div class="h-full">
    <!-- 1st Row -->
    <div class="d-flex align-center px-sm-6 px-4 py-2">
      <div
        class="d-flex align-center cursor-pointer mr-4"
        @click="$emit('close-contact-request-view')"
      >
        <!-- Back Button -->
        <v-btn
          icon
          small
          class="me-2"
        >
          <v-icon size="28">
            {{ icons.mdiChevronLeft }}
          </v-icon>
        </v-btn>

        <!-- Subject -->
        <h1 class="font-weight-medium text-base me-2 text-truncate">
          <!-- {{ contactRequestView.subject }} -->
          {{ getContactRequestTitle(contactRequestView) }}
        </h1>
      </div>

      <!-- Labels -->
      <v-badge
        v-for="label in contactRequestView.labels"
        :key="label"
        :color="resolveContactRequestLabelColor(label)"
        inline
        :content="label"
        class="email-label-chip text-capitalize v-badge-light-bg"
        :class="`${resolveContactRequestLabelColor(label)}--text`"
      >
      </v-badge>

      <!-- Navigation Prev Vs Next -->
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        :disabled="!openContactRequestMeta.hasPrev"
        @click="$emit('change-open-contact-request', 'prev')"
      >
        <v-icon size="28">
          {{ icons.mdiChevronLeft }}
        </v-icon>
      </v-btn>
      <v-btn
        icon
        small
        :disabled="!openContactRequestMeta.hasNext"
        @click="$emit('change-open-contact-request', 'next')"
      >
        <v-icon size="28">
          {{ icons.mdiChevronRight }}
        </v-icon>
      </v-btn>
    </div>

    <!-- 2nd Row -->
    <v-divider></v-divider>
    <div class="d-flex align-center px-sm-6 px-4 py-2">
      <!-- Actions: Send trash (Only !== trash) -->
      <!-- <v-btn
        v-show="$route.params.folder !== 'trash'"
        icon
        small
        class="mr-2"
        @click="$emit('move-contact-request-to-folder', 'trash')"
      >
        <v-icon size="22">
          {{ icons.mdiTrashCanOutline }}
        </v-icon>
      </v-btn> -->

      <!-- Actions: Set labels to ['Personal', 'Company', ...] -->
      <MenuLabels
        :is-visible="true"
        :update-label-menu-list-items="updateLabelMenuListItems"
        @on-label-click="(value) => $emit('update-contact-request-label', value)"
      ></MenuLabels>

      <v-spacer></v-spacer>

      <!-- Show status -->
      <div class="d-flex align-center mr-4">
        <!-- :color="resolveContactRequestStatus(contactRequestView.status).color" -->
        <v-badge
          inline
          class="email-label-chip text-capitalize v-badge-light-bg"
          :class="`${resolveContactRequestStatus(contactRequestView.status).color}--text`"
          :content="contactRequestView.status"
        />
        <v-badge
          v-if="contactRequestView.contactRequestInboundRelation.status === CONTACT_REQUEST_INBOUND_STATUS.ACCEPTED"
          inline
          class="email-label-chip text-capitalize v-badge-light-bg"
          :class="`${resolveContactRequestInboundStatus(contactRequestView.contactRequestInboundRelation.status).color}--text`"
          :content="avatarText(contactRequestView.contactRequestInboundRelation.status)"
        />
        <ContactChatMessageNavegable
          v-if="contactRequestView.contactRelation"
          :contact="contactRequestView.contactRelation"
          :is-navegable="true"
        />
      </div>

      <!-- Other actions -->
      <!-- Custom Actions: Published | Finished -->
      <!-- Draft: La puedo pubicar o editar -->
      <v-btn
        v-if="contactRequestView.status === CONTACT_REQUEST_STATUS.DRAFT"
        :icon="!$vuetify.breakpoint.smAndUp"
        outlined
        small
        class="ml-2"
        :color="customActionPublished.color"
        @click="$emit('set-contact-request-action', customActionPublished.value)"
      >
        <v-icon size="22">
          {{ customActionPublished.icon }}
        </v-icon>
        <span
          v-if="$vuetify.breakpoint.smAndUp"
          class="pl-2"
        >{{ customActionPublished.title }}</span>
      </v-btn>
      <v-btn
        v-if="contactRequestView.status === CONTACT_REQUEST_STATUS.DRAFT"
        small
        outlined
        class="ml-2"
        color="warning"
        @click="$emit('contact-request-edit', contactRequestView.id)"
      >
        <v-icon size="22">
          {{ icons.mdiCircleEditOutline }}
        </v-icon>
        <span class="pl-2">Edit</span>
      </v-btn>

      <!-- Published: La puedo finalizar -->
      <v-btn
        v-if="contactRequestView.status === CONTACT_REQUEST_STATUS.PUBLISHED"
        icon
        small
        class="ml-2"
        :color="customActionFinished.color"
        @click="$emit('set-contact-request-action', customActionFinished.value)"
      >
        <v-icon size="22">
          {{ customActionFinished.icon }}
        </v-icon>
      </v-btn>

      <!-- DELETE -->
      <v-btn
        icon
        small
        outlined
        class="ml-2"
        color="error"
        @click="$emit('on-delete', contactRequestView)"
      >
        <v-icon size="22">
          {{ icons.mdiTrashCanOutline }}
        </v-icon>
      </v-btn>
    </div>

    <v-divider></v-divider>

    <perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="ps-contact-request-view-content"
    >
      <!-- ContactRequest Messages -->
      <div
        class="position-relative ma-sm-7 ma-4"
      >
        <!-- <contact-request-message-card
          :message="contactRequestView"
          style="top:0;z-index:10"
        /> -->

        <!-- Cards: Supplier and ProductSupplier -->
        <v-card
          outlined
          class="mt-4"
          style="top:0;z-index:10"
        >
          <v-card-text class="d-flex flex-wrap align-center pa-4">
            <v-btn
              v-if="$vuetify.breakpoint.smAndUp"
              icon
              small
              class="me-2"
              :color="contactRequestView.isStarred ? 'warning': null"
              @click.stop="$emit('toggle-contact-request-starred', contactRequestView)"
            >
              <v-icon>
                {{ contactRequestView.isStarred ? icons.mdiStar : icons.mdiStarOutline }}
              </v-icon>
            </v-btn>

            <!-- Cards: Supplier(His) and ProductSupplier(His) -->
            <!-- <SupplierCard :supplier="contactRequestView.productSupplier.supplier" /> -->
            <!-- <ProductSupplierCard :product-supplier="contactRequestView.productSupplier" /> -->
            <ContactRequestCard
              :contact-request="contactRequestView"
              class="flex-wrap"
            />

            <!-- Right -->
            <v-spacer></v-spacer>
            <span
              v-if="$vuetify.breakpoint.smAndUp"
              class="text--disabled text-xs me-2 text-no-wrap"
            >
              {{ formatDate(contactRequestView.createdAt, { dateStyle: 'long', timeStyle: 'short' }) }}
            </span>
            <div
              v-else
              class="d-flex flex-column align-center"
            >
              <v-btn
                icon
                small
                :color="contactRequestView.isStarred ? 'warning': null"
                @click.stop="$emit('toggle-contact-request-starred', contactRequestView)"
              >
                <v-icon>
                  {{ contactRequestView.isStarred ? icons.mdiStar : icons.mdiStarOutline }}
                </v-icon>
              </v-btn>
              <span class="text--disabled text-xs">{{ formatDateToMonthShort(contactRequestView.createdAt, { hour: 'numeric', minute: 'numeric', }) }}</span>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <!-- Message -->
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="ma-5"
            v-html="contactRequestView.message"
          ></div>
        </v-card>

        <!-- Content -->
        <v-row class="mt-4">
          <v-col
            cols="12"
            lg="6"
            md="6"
          >
            <ContactRequestContent :content-data="contactRequestView"></ContactRequestContent>
          </v-col>
        </v-row>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { avatarText, formatDate, formatDateToMonthShort } from '@core/utils/filter'
import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiClose,
  mdiLabelOutline,
  mdiStar,
  mdiStarOutline,
  mdiTrashCanOutline,
  mdiCircleEditOutline,
} from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import useContactRequestHandler from './useContactRequestHandler'

// Local Components
// import ContactRequestViewMessageCard from './ContactRequestViewMessageCard.vue'
// import ProductSupplierCard from '@/components/Cards/ProductSupplierCard.vue'
// import SupplierCard from '@/components/Cards/SupplierCard.vue'
import ContactRequestCard from '@/components/Cards/ContactRequestCard.vue'
import ContactRequestContent from './ContactRequestContent.vue'
import MenuLabels from '@/components/MenuLabels.vue'
import useContactRequestInboundHandler from '../contact-request-inbound/useContactRequestInboundHandler'

import ContactChatMessageNavegable from '@/components/NavegableIcon/ContactChatMessageNavegable.vue'

export default {
  components: {
    PerfectScrollbar,

    // Local Components
    ContactRequestCard,
    ContactRequestContent,
    MenuLabels,

    ContactChatMessageNavegable,
  },
  props: {
    contactRequestView: {
      type: Object,
      required: true,
    },
    openContactRequestMeta: {
      type: Object,
      required: true,
    },
  },
  setup() {
    // UserName + CompanyName
    const getUserName = user => (user ? `${user?.firstName ?? ''} ${user?.lastName ?? ''}` : '')
    const getCompanyName = company => company?.general?.name || ''

    // Title: User + Company
    const getContactRequestTitle = contactRequest => `${getUserName(contactRequest?.productSupplier?.supplier?.user)} (${getCompanyName(contactRequest?.productSupplier?.supplier?.company)})`

    // ————————————————————————————————————
    //* ——— useContactRequest
    // ————————————————————————————————————
    const {
      resolveContactRequestLabelColor,
      resolveContactRequestStatus,
      customActionPublished,
      customActionFinished,
      updateLabelMenuListItems,
      CONTACT_REQUEST_STATUS,
    } = useContactRequestHandler()

    // For Join status (ContactRequest + ContactRequestInbound)
    const {
      resolveContactRequestInboundStatus,
      CONTACT_REQUEST_INBOUND_STATUS,
    } = useContactRequestInboundHandler()

    // ————————————————————————————————————
    //* ——— Perfect Scrollbar
    // ————————————————————————————————————
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      getContactRequestTitle, // User + Company

      // useContactRequest
      resolveContactRequestLabelColor,
      resolveContactRequestStatus,
      customActionPublished,
      customActionFinished,
      updateLabelMenuListItems,
      CONTACT_REQUEST_STATUS,

      // useContactRequestInbound - Join status (ContactRequest + ContactRequestInbound)
      resolveContactRequestInboundStatus,
      CONTACT_REQUEST_INBOUND_STATUS,

      // Filter/Formatter
      avatarText,
      formatDate,
      formatDateToMonthShort,

      // Perfect Scrollbar
      perfectScrollbarSettings,

      // Icons
      icons: {
        mdiChevronLeft,
        mdiChevronRight,
        mdiTrashCanOutline,
        mdiLabelOutline,
        mdiStarOutline,
        mdiStar,
        mdiClose,
        mdiCircleEditOutline,
      },
    }
  },
}
</script>

<style lang="scss">
  @import '~@core/preset/preset/mixins.scss';
  // @import '~vuetify/src/components/VCard/_variables.scss';

  @include theme--child(ps-contact-request-view-content) using ($material) {
    background-color: rgba(map-deep-get($material, 'primary-shade'), 0.04);
  }

  .ps-contact-request-view-content {
    height: calc(100% - 46px - 46px - 2px);

    // .view-all-toggler {
    //   &:hover {
    //     color: var(--v-primary-base) !important;
    //   }
    // }
  }
</style>
