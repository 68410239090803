import {
  mdiShield,
} from '@mdi/js'

// Notification
import { useToast } from 'vue-toastification/composition'
import { POSITION, TYPE } from 'vue-toastification'
import ToastificationContent from '@/components/ToastificationContent.vue'

// Dependencies
import { CONTACT_REQUEST_STATUS, resolveContactRequestStatus } from '@/services/master/ContactRequestStatus'
import useRouterPath from '@/router/useRouterPath'

export default function useContactRequestHandler() {
  // Esto es para traducir las constantes (Textos fijos como Roles, Estados, ...)
  // const { t, te } = useI18nUtils()
  const toast = useToast()

  // ------------------------------------------------
  // (UI) Notification
  // ------------------------------------------------
  const sendContactRequestNotification = (title, variant = TYPE.ERROR, icon = mdiShield) => {
    // toast.warning(`Product Supplier ${action}`)
    toast({
      component: ToastificationContent,
      props: {
        title: `ContactRequest - ${title}`,
        variant,
        icon,
      },
    }, { position: POSITION.BOTTOM_CENTER, type: variant })
  }

  // LABELS
  const updateLabelMenuListItems = [
    { title: 'Personal', value: 'personal', color: 'success' },
    { title: 'Company', value: 'company', color: 'primary' },
    { title: 'Important', value: 'important', color: 'warning' },
    { title: 'Private', value: 'private', color: 'error' },
  ]

  const resolveContactRequestLabelColor = label => {
    if (label === 'personal') return 'success'
    if (label === 'company') return 'primary'
    if (label === 'important') return 'warning'
    if (label === 'private') return 'error'

    return 'secondary'
  }

  const {
    menuItemContactRequestPublished,
    menuItemContactRequestDraft,
    menuItemContactRequestFinished,
  } = useRouterPath()

  // CUSTOM ACTIONS
  const customActionPublished = {
    title: menuItemContactRequestPublished.value.text,
    value: menuItemContactRequestPublished.value.value,
    color: menuItemContactRequestPublished.value.color,
    icon: menuItemContactRequestPublished.value.icon,
    route: menuItemContactRequestPublished.value.route,
  }
  const customActionDraft = {
    title: menuItemContactRequestDraft.text,
    value: menuItemContactRequestDraft.value,
    color: menuItemContactRequestDraft.color,
    icon: menuItemContactRequestDraft.icon,
    route: menuItemContactRequestDraft.route,
  }
  const customActionFinished = {
    title: menuItemContactRequestFinished.text,
    value: menuItemContactRequestFinished.value,
    color: menuItemContactRequestFinished.color,
    icon: menuItemContactRequestFinished.icon,
    route: menuItemContactRequestFinished.route,
  }

  return {
    sendContactRequestNotification,

    // Custom Actions
    customActionPublished,
    customActionDraft,
    customActionFinished,

    // Selected Mail Actions Menu List Item
    updateLabelMenuListItems,

    // UI ContactRequest
    resolveContactRequestLabelColor,
    resolveContactRequestStatus,
    CONTACT_REQUEST_STATUS,
  }
}
