<template>
  <!-- Add class="flex-wrap" al padre para mostrar todo en dispositivos SM -->
  <div class="d-flex align-center">
    <!-- Cards: Supplier(His) and ProductSupplier(His) -->
    <SupplierCard :supplier="contactRequest.productSupplier.supplier" />
    <ProductSupplierCard :product-supplier="contactRequest.productSupplier" />

    <!-- :class="`${resolveContactRequestLabelColor(label)}--text`" -->
    <!-- labels  > 1: Indicamos solo los puntos (Sin Opacidad) -->
    <!-- labels == 1: Indicamos el texto (Con Opacidad) -->
    <!-- <div class="d-flex flex-column text-truncate">
        <span class="text-truncate me-4 ms-sm-2 ms-0">{{ contactRequest.subject }}</span>
        <div class="d-flex flex-row text-truncate">
          <template v-if="$vuetify.breakpoint.smAndUp">
            <v-badge
              v-for="label in contactRequest.labels"
              :key="label"
              :color="resolveContactRequestLabelColor(label)"
              :dot="contactRequest.labels.length > 1"
              inline
              class="align-self-center label-dot"
              :class="!(contactRequest.labels.length > 1) ? `${resolveContactRequestLabelColor(label)}--text v-badge-light-bg` : ''"
              :content="label"
            ></v-badge>
          </template>
        </div>
      </div> -->
  </div>
</template>

<script>

// Components
import SupplierCard from '@/components/Cards/SupplierCard.vue'
import ProductSupplierCard from '@/components/Cards/ProductSupplierCard.vue'
import useContactRequestHandler from '@/views/apps/contact-request/contact-request/useContactRequestHandler'

export default {
  components: {
    SupplierCard,
    ProductSupplierCard,
  },
  props: {
    contactRequest: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { resolveContactRequestLabelColor } = useContactRequestHandler()

    return {
      resolveContactRequestLabelColor,
    }
  },
}
</script>
