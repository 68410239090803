<template>
  <!-- Main card: El auto load no funciona con altura fija -->
  <v-card
    class="rounded-0 app-contact-request max-h-content-container h-full-- position-relative overflow-hidden text-sm"
  >
    <!-- Delete -->
    <DialogDelete
      :id="contactRequestDeleteId"
      v-model="isContactRequestDeleteDialogActive"
      :dependencies-error="contactRequestDeleteDependenciesError"
      :title="$t('ContactRequestList.table.dialog_delete.title')"
      :text="$t('ContactRequestList.table.dialog_delete.text')"
      :button="$t('ContactRequestList.table.dialog_delete.button_text')"
      @delete="(id) => onContactRequestDelete(id, false)"
    ></DialogDelete>

    <!-- Left Sidebar: Filters -->
    <v-navigation-drawer
      v-model="isLeftSidebarOpen"
      width="260"
      mobile-breakpoint="sm"
      absolute
      :right="$vuetify.rtl"
      touchless
      :temporary="$vuetify.breakpoint.smAndDown"
    >
      <!-- :is-contact-request-compose-dialog-visible="isContactRequestComposeDialogVisible" -->
      <contact-request-left-sidebar-content
        :contact-requests-meta="contactRequestsMeta"
      ></contact-request-left-sidebar-content>
    </v-navigation-drawer>

    <!-- Content Area -->
    <div
      class="h-full position-relative d-flex flex-column"
      :style="contentStyles"
    >
      <!-- 1st Row -->
      <div class="d-flex align-center px-4">
        <v-btn
          v-show="!isLeftSidebarOpen"
          :icon="false"
          outlined
          small
          color="primary"
          @click="isLeftSidebarOpen = true"
        >
          <v-icon size="22">
            {{ icons.mdiTune }}
          </v-icon>
          <span class="pl-2">{{ $route.params.folder || $route.params.label }}</span>
        </v-btn>

        <v-text-field
          v-model="searchQuery"
          placeholder="search..."
          outlined
          hide-details
          class="contact-request-search-input"
          :prepend-inner-icon="icons.mdiMagnify"
        ></v-text-field>
      </div>

      <v-divider></v-divider>

      <!-- 2nd Row -->
      <div class="d-flex align-center px-4 py-2">
        <v-checkbox
          class="d-flex mt-0 pt-0"
          hide-details
          :input-value="selectAllContactRequestCheckbox"
          :indeterminate="isSelectAllContactRequestCheckboxIndeterminate"
          :disabled="!contactRequests.length"
          @change="selectAllCheckboxUpdate"
        ></v-checkbox>

        <!-- Action Multiple: Labels -->
        <MenuLabels
          :is-visible="(selectedContactRequests.length > 0)"
          :update-label-menu-list-items="updateLabelMenuListItems"
          @on-label-click="(value) => updateSelectedContactRequestLabelMultiple(value)"
        ></MenuLabels>

        <!-- Multiple: Custom Actions -->
        <div
          v-if="selectedContactRequests.length"
          class="me-2"
        >
          <!-- Published: Las puedo finalizar -->
          <v-btn
            v-if="$route.params.folder === CONTACT_REQUEST_STATUS.PUBLISHED"
            icon
            small
            class="me-2"
            :color="customActionFinished.color"
            @click.stop="setSelectedContactRequestActionMultiple(customActionFinished.value)"
          >
            <v-icon size="22">
              {{ customActionFinished.icon }}
            </v-icon>
          </v-btn>

          <!-- Draft: Las puedo publicar -->
          <v-btn
            v-if="$route.params.folder === CONTACT_REQUEST_STATUS.DRAFT"
            icon
            small
            class="me-2"
            :color="customActionPublished.color"
            @click.stop="setSelectedContactRequestActionMultiple(customActionPublished.value)"
          >
            <v-icon size="22">
              {{ customActionPublished.icon }}
            </v-icon>
          </v-btn>

          <!-- DELETE -->
          <v-btn
            v-if="$route.params.folder === CONTACT_REQUEST_STATUS.FINISHED"
            icon
            small
            outlined
            class="me-2"
            color="error"
            @click.stop="deleteSelectedContactRequestMultiple()"
          >
            <v-icon size="22">
              {{ icons.mdiTrashCanOutline }}
            </v-icon>
          </v-btn>
        </div>

        <!-- Right Side -->
        <v-spacer></v-spacer>
        <v-btn
          v-if="!finished"
          text
          small
          class="me-2"
          @click="onLoad()"
        >
          Load more
        </v-btn>
        <v-btn
          icon
          small
          class="me-2"
          @click="onRefresh()"
        >
          <v-icon size="21">
            {{ icons.mdiReload }}
          </v-icon>
        </v-btn>
      </div>

      <!-- Loading indicator -->
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
        class="position-absolute"
      />
      <v-divider></v-divider>

      <!-- ContactResquests List -->
      <!-- <perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="ps-contact-request-list"
      > -->
      <vue-loadmore
        ref="refLoadMore"
        :on-refresh="onRefresh"
        :on-loadmore="onLoad"
        :finished="finished"
        :error.sync="loadMoreError"
      >
        <v-hover
          v-for="contactRequest in contactRequests"
          #default="{ hover: isHovered }"
          :key="contactRequest.id"
        >
          <div
            :class="[{'hovered elevation-3': isHovered}, { 'contact-request-read': contactRequest.isRead }]"
            class="contact-request-item pa-4 d-flex align-center text-no-wrap cursor-pointer"
            @click="updateContactRequestView(contactRequest)"
          >
            <v-checkbox
              class="d-flex mt-0 pt-0"
              hide-details
              :input-value="selectedContactRequests.includes(contactRequest.id)"
              @click.stop
              @change="toggleSelectedContactRequest(contactRequest.id)"
            ></v-checkbox>
            <v-btn
              v-if="$vuetify.breakpoint.smAndUp"
              icon
              small
              class="me-2"
              :color="contactRequest.isStarred ? 'warning': null"
              @click.stop="toggleStarred(contactRequest)"
            >
              <v-icon>
                {{ contactRequest.isStarred ? icons.mdiStar : icons.mdiStarOutline }}
              </v-icon>
            </v-btn>

            <!-- Cards: Supplier(His) and ProductSupplier(His) -->
            <!-- <SupplierCard :supplier="contactRequest.productSupplier.supplier" /> -->
            <!-- <ProductSupplierCard :product-supplier="contactRequest.productSupplier" /> -->
            <ContactRequestCard
              :contact-request="contactRequest"
              :width="$vuetify.breakpoint.smAndUp ? '250px' : '200px'"
            />

            <div class="d-flex flex-column text-truncate">
              <!-- <span class="text-truncate me-4 ms-sm-2 ms-0">{{ contactRequest.subject }}</span> -->
              <div class="d-flex flex-row text-truncate">
                <template v-if="$vuetify.breakpoint.smAndUp">
                  <!-- :class="`${resolveContactRequestLabelColor(label)}--text`" -->
                  <!-- labels  > 1: Indicamos solo los puntos (Sin Opacidad) -->
                  <!-- labels == 1: Indicamos el texto (Con Opacidad) -->
                  <v-badge
                    v-for="label in contactRequest.labels"
                    :key="label"
                    :color="resolveContactRequestLabelColor(label)"
                    :dot="contactRequest.labels.length > 1"
                    inline
                    class="align-self-center label-dot"
                    :class="!(contactRequest.labels.length > 1) ? `${resolveContactRequestLabelColor(label)}--text v-badge-light-bg` : ''"
                    :content="label"
                  ></v-badge>
                </template>
              </div>
            </div>

            <v-spacer></v-spacer>

            <!-- Time and Actions On Hover -->
            <div v-show="isHovered">
              <ContactChatMessageNavegable
                v-if="contactRequest.contactRelation"
                :contact="contactRequest.contactRelation"
                :is-navegable="true"
              />

              <!-- Custom Actions: Published | Finished -->
              <!-- Draft: La puedo pubicar o editar -->
              <v-btn
                v-if="contactRequest.status === CONTACT_REQUEST_STATUS.DRAFT"
                icon
                small
                class="me-2"
                :color="customActionPublished.color"
                @click.stop="setSelectedContactRequestActionMultiple(customActionPublished.value, [contactRequest.id])"
              >
                <v-icon size="22">
                  {{ customActionPublished.icon }}
                </v-icon>
              </v-btn>

              <!-- Published: La puedo finalizar -->
              <v-btn
                v-if="contactRequest.status === CONTACT_REQUEST_STATUS.PUBLISHED"
                icon
                small
                class="me-2"
                :color="customActionFinished.color"
                @click.stop="setSelectedContactRequestActionMultiple(customActionFinished.value, [contactRequest.id])"
              >
                <v-icon size="22">
                  {{ customActionFinished.icon }}
                </v-icon>
              </v-btn>

              <!-- Action: Delete permanent (Only when finished) -->
              <!-- @click.stop="deleteSelectedContactRequestMultiple([contactRequest.id])" -->
              <v-btn
                v-if="contactRequest.status === CONTACT_REQUEST_STATUS.FINISHED"
                icon
                small
                class="me-2"
                color="error"
                @click.stop="askContactRequestDelete(contactRequest.id)"
              >
                <v-icon size="22">
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </div>

            <!-- Not hovered: Status + Time -->
            <div v-show="!isHovered">
              <div class="d-flex flex-column align-center">
                <span class="text-xs text--disabled">{{ formatDateToMonthShort(contactRequest.createdAt, { hour: 'numeric', minute: 'numeric', }) }}</span>
                <div class="d-flex align-center">
                  <v-badge
                    inline
                    class="email-label-chip text-capitalize v-badge-light-bg"
                    :class="`${resolveContactRequestStatus(contactRequest.status).color}--text`"
                    :content="contactRequest.status"
                  ></v-badge>
                  <v-badge
                    v-if="contactRequest.contactRequestInboundRelation.status === CONTACT_REQUEST_INBOUND_STATUS.ACCEPTED"
                    inline
                    class="email-label-chip text-capitalize v-badge-light-bg"
                    :class="`${resolveContactRequestInboundStatus(contactRequest.contactRequestInboundRelation.status).color}--text`"
                    :content="avatarText(contactRequest.contactRequestInboundRelation.status)"
                  ></v-badge>
                </div>
              </div>
            </div>
          </div>
        </v-hover>

        <!-- LoadMore button -->
        <v-progress-linear
          v-if="loading"
          indeterminate
          reverse
          color="primary"
          class="position-absolute"
        ></v-progress-linear>
        <v-divider></v-divider>
        <div class="d-flex justify-center">
          <v-btn
            v-if="!finished"
            text
            small
            class="me-2"
            @click="onLoad()"
          >
            Load more
          </v-btn>
        </div>
      </vue-loadmore>
      <!-- </perfect-scrollbar> -->
      <div
        class="d-none text-center text--primary font-weight-medium"
        :class="{'d-block': !contactRequests.length}"
      >
        <p class="my-4">
          No Items Found
        </p>
      </div>

      <!-- ContactRequest Details -->
      <v-navigation-drawer
        v-model="isContactRequestViewOpen"
        :right="!$vuetify.rtl"
        absolute
        mobile-breakpoint="sm"
        width="100%"
      >
        <!-- @move-contact-request-to-folder="moveOpenContactRequestToFolder" -->
        <!-- @mark-contact-request-unread="markOpenContactRequestAsUnread" -->
        <contact-request-view
          v-if="contactRequestView.productSupplier"
          :contact-request-view="contactRequestView"
          :open-contact-request-meta="openContactRequestMeta"
          @change-open-contact-request="changeOpenContactRequest"
          @close-contact-request-view="isContactRequestViewOpen = false"
          @update-contact-request-label="updateOpenContactRequestLabel"

          @toggle-contact-request-starred="toggleStarred(contactRequestView)"
          @set-contact-request-action="setOpenContactRequestAction"
          @on-delete="(contactRequest) => askContactRequestDelete(contactRequest.id)"
        ></contact-request-view>
      </v-navigation-drawer>
    </div>
  </v-card>
</template>

<script>
import { useResponsiveLeftSidebar } from '@core/comp-functions/ui'
import { useRouter } from '@core/utils'
import {
  mdiTune,
  mdiAlertOctagonOutline,
  mdiAttachment,
  mdiChevronLeft,
  mdiChevronRight,
  mdiDotsVertical,
  mdiEmailOpenOutline,
  mdiEmailOutline,
  mdiFolderOutline,
  mdiLabelOutline,
  mdiMagnify,
  mdiMenu,
  mdiReload,
  mdiStar,
  mdiStarOutline,
  mdiTrashCanOutline,
} from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

// Store
// eslint-disable-next-line object-curly-newline
import { avatarText, formatDateToMonthShort } from '@core/utils/filter'
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'

// Services
import useContactRequest from '@/services/api/modules/contactRequest'
import useContactRequestHandler from './useContactRequestHandler'

// Local Componnets
// import ProductSupplierCard from '@/components/Cards/ProductSupplierCard.vue'
// import SupplierCard from '@/components/Cards/SupplierCard.vue'
import ContactRequestCard from '@/components/Cards/ContactRequestCard.vue'
import ContactRequestLeftSidebarContent from './ContactRequestLeftSidebarContent.vue'
import ContactRequestView from './ContactRequestView.vue'
import MenuLabels from '@/components/MenuLabels.vue'

import DialogDelete from '@/components/DialogDelete.vue'
import useContactRequestInboundHandler from '../contact-request-inbound/useContactRequestInboundHandler'

import ContactChatMessageNavegable from '@/components/NavegableIcon/ContactChatMessageNavegable.vue'

export default {
  components: {
    ContactRequestLeftSidebarContent,
    ContactRequestView,
    ContactRequestCard,
    MenuLabels,

    DialogDelete,
    ContactChatMessageNavegable,

    // 3rd Party
    // eslint-disable-next-line
    PerfectScrollbar,
  },
  setup() {
    // Register module
    // const MARKETPLACE_APP_STORE_MODULE_NAME = 'marketplace'
    // if (!store.hasModule(MARKETPLACE_APP_STORE_MODULE_NAME)) store.registerModule(MARKETPLACE_APP_STORE_MODULE_NAME, marketplaceStoreModule)
    // onUnmounted(() => {
    //     if (store.hasModule(MARKETPLACE_APP_STORE_MODULE_NAME)) store.unregisterModule(MARKETPLACE_APP_STORE_MODULE_NAME)
    // })

    // LeftSidebar management (Integrated with Main Component)
    const { isLeftSidebarOpen, contentStyles } = useResponsiveLeftSidebar({ sidebarWidth: 260 })

    // ————————————————————————————————————
    //* ——— Router
    // ————————————————————————————————————
    const { route, router } = useRouter()

    // Search Query & Route Params
    const searchQuery = computed({
      get: () => route.value.query.q,
      set: val => {
        const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

        if (val) currentRouteQuery.q = val
        else delete currentRouteQuery.q

        router.replace({ name: route.name, query: currentRouteQuery })
      },
    })
    const routeParams = computed(() => route.value.params)

    // Fetch contactRequest again of search query change or route param change
    // eslint-disable-next-line no-use-before-define
    watch([searchQuery, routeParams], () => fetchContactRequests())

    // ————————————————————————————————————
    //* ——— useContactRequest
    // ————————————————————————————————————
    const {
      sendContactRequestNotification,
      resolveContactRequestLabelColor,
      resolveContactRequestStatus,
      customActionPublished,
      customActionFinished,
      updateLabelMenuListItems,
      CONTACT_REQUEST_STATUS,
    } = useContactRequestHandler()

    // For Join status (ContactRequest + ContactRequestInbound)
    const {
      resolveContactRequestInboundStatus,
      CONTACT_REQUEST_INBOUND_STATUS,
    } = useContactRequestInboundHandler()

    // ————————————————————————————————————
    //* ——— ContactRequests & ContactRequests Meta (LeftSide)
    // ————————————————————————————————————
    const contactRequests = ref([])
    const contactRequestsMeta = ref({})
    const loading = ref(false)
    const finished = ref(false)
    const loadMoreError = ref(false)
    const refLoadMore = ref(null)

    const scrollToToInLoadMore = () => {
      const scrollEl = refLoadMore.value.$el || refLoadMore.value
      scrollEl.scrollTop = 0
    }

    // Options: Pagination, ... (See useMarketplaceCatalog for example)
    const itemsPerPageBase = 6
    const options = ref({
      page: 1,
      itemsPerPage: itemsPerPageBase,
    })

    // When pull refresh
    const onRefresh = done => {
      // Reset pagination
      options.value.page = 1
      options.value.itemsPerPage = itemsPerPageBase

      finished.value = false

      // eslint-disable-next-line no-use-before-define
      fetchContactRequests()

      scrollToToInLoadMore()
      if (done !== undefined) {
        done()
      }
    }

    // When scroll to the botton
    const onLoad = done => {
      console.log('onLoadMore')
      if (!finished.value) {
        // options.value.page += 1
        options.value.itemsPerPage += itemsPerPageBase

        // eslint-disable-next-line no-use-before-define
        fetchContactRequests()
      }

      if (done !== undefined) {
        done()
      }
    }

    const fetchContactRequests = () => {
      loading.value = true

      // folder meaning
      //  published filter by status (Default search)
      //  draft     filter by status
      //  finished  filter by status
      //  starred   filter by isStarred
      //  trash     filter by isDeleted or status deleted
      useContactRequest
        .fetchContactRequests({
          q: searchQuery.value,
          perPage: options.value.itemsPerPage,
          page: options.value.page,

          // folder: router.currentRoute.params.folder || 'published',
          folder:
            router.currentRoute.params.folder
            || (router.currentRoute.params.label ? undefined : CONTACT_REQUEST_STATUS.PUBLISHED),
          label: router.currentRoute.params.label,
        })
        .then(response => {
          const {
            contactRequests: contactRequestsResponse,
            contactRequestsMeta: contactRequestsMetaResponse,
            total,
          } = response.data
          if (contactRequestsResponse) {
            contactRequests.value = contactRequestsResponse
            contactRequestsMeta.value = contactRequestsMetaResponse
          } else {
            contactRequests.value = []
            contactRequestsMeta.value = {}
          }
          finished.value = (options.value.page * options.value.itemsPerPage) >= total

          // Store
          // const totalPending = contactRequestsMeta.value['CONTACT_REQUEST_STATUS.PUBLISHED'] || 0
          // const totalPending = contactRequestsMeta.value?.published || 0
          const { published } = contactRequestsMeta.value
          store.commit('user/UPDATE_CONTACT_REQUESTS_PENDING_COUNT', published || 0)
        })
        .catch(() => {
          loadMoreError.value = true
        })
        .finally(() => {
          loading.value = false
        })
    }
    fetchContactRequests()

    // Navegación: Se ha indicado un id (Buscamos en los query params)
    //  Navegación automática -> TODO - Mejor abrir una modal de edición (Por ejemplo ContactRequestInboundComposerDialog)
    const contactRequestIdNavigation = route.value.query.contactRequestId
    const fetchContactRequest = contactRequestId => {
      useContactRequest.fetchContactRequest(contactRequestId)
        .then(response => {
          // eslint-disable-next-line
          updateContactRequestView(response.data.contactRequest)
        })
    }
    if (contactRequestIdNavigation) {
      fetchContactRequest(contactRequestIdNavigation)
    }

    // ————————————————————————————————————
    //* ——— ContactRequests Selection
    // ————————————————————————————————————
    const selectedContactRequests = ref([])
    const toggleSelectedContactRequest = contactRequestId => {
      const contactRequestIndex = selectedContactRequests.value.indexOf(contactRequestId)

      if (contactRequestIndex === -1) selectedContactRequests.value.push(contactRequestId)
      else selectedContactRequests.value.splice(contactRequestIndex, 1)
    }
    const selectAllContactRequestCheckbox = computed(
      () => contactRequests.value.length && contactRequests.value.length === selectedContactRequests.value.length,
    )
    const isSelectAllContactRequestCheckboxIndeterminate = computed(
      () => Boolean(selectedContactRequests.value.length)
        && contactRequests.value.length !== selectedContactRequests.value.length,
    )
    const selectAllCheckboxUpdate = val => {
      selectedContactRequests.value = val ? contactRequests.value.map(contactRequest => contactRequest.id) : []
    }
    const isAllSelectedContactRequestAreRead = computed(() => {
      // Get array of isRead property value from selected contactRequests array
      const contactRequestsIsRead = selectedContactRequests.value.map(
        contactRequestId => contactRequests.value.find(contactRequest => contactRequest.id === contactRequestId).isRead,
      )

      // Return icon based on selection (every comprueba si todos los elementos cumplen una condición)
      return contactRequestsIsRead.every(contactRequestIsRead => contactRequestIsRead)
    })

    // ? Watcher to reset selectedContactRequests is somewhere below due to watch dependecy fullfilment

    // ————————————————————————————————————
    //* ——— ContactRequest Actions
    // ————————————————————————————————————
    const toggleStarred = contactRequest => {
      useContactRequest.changeContactRequestStarred(
        contactRequest.id,
        !contactRequest.isStarred,
      )
        .then(() => {
        // eslint-disable-next-line no-param-reassign
          contactRequest.isStarred = !contactRequest.isStarred
        })
    }

    const updateSelectedContactRequestLabelMultiple = (
      label,
      contactRequestIds = selectedContactRequests.value,
    ) => {
      const tasks = contactRequestIds.map(contactRequestId => useContactRequest.changeContactRequestLabels(contactRequestId, label))
      Promise.all(tasks).finally(() => {
        fetchContactRequests()
        selectedContactRequests.value = []
      })
    }

    // Activado: Se permite en las finalizadas
    const deleteSelectedContactRequestMultiple = (
      contactRequestIds = selectedContactRequests.value,
    ) => {
      const tasks = contactRequestIds.map(contactRequestId => useContactRequest.removeContactRequest(contactRequestId))
      Promise.all(tasks).finally(() => {
        fetchContactRequests()
        selectedContactRequests.value = []
      })
    }

    // From ContactRequestView: Create new, Edit current, Withdraw current
    //  actions: [published | finished]
    // const setContactRequestAction = (
    //   action,
    //   contactRequestId,
    // ) => {
    //   // Withdraw action: Change status to FINISHED
    //   // if (action === CONTACT_REQUEST_STATUS.PUBLISHED || action === CONTACT_REQUEST_STATUS.FINISHED) {
    //   useContactRequest.setContactRequestStatus(contactRequestId, action)
    //     .then(() => {
    //       sendContactRequestNotification(`ContactRequest ${action}`)
    //     })
    //     .finally(() => {
    //       fetchContactRequests()
    //       selectedContactRequests.value = []
    //     })
    // }
    const setSelectedContactRequestActionMultiple = (
      action,
      contactRequestIds = selectedContactRequests.value,
    ) => {
      // TODO - Notificar al final sendContactRequestNotification(`ContactRequest ${action}`)
      const tasks = contactRequestIds.map(contactRequestId => useContactRequest.setContactRequestStatus(contactRequestId, action))
      Promise.all(tasks)
        .finally(() => {
          fetchContactRequests()
          selectedContactRequests.value = []
        })
    }

    // ————————————————————————————————————
    //* ——— ContactRequest View
    // ————————————————————————————————————
    const isContactRequestViewOpen = ref(false)
    const contactRequestView = ref({})

    // >>> Open Meta (Prev < > Next)
    const openContactRequestMeta = computed(() => {
      const openContactRequestIndex = contactRequests.value.findIndex(e => e.id === contactRequestView.value.id)

      return {
        hasNext: Boolean(contactRequests.value[openContactRequestIndex + 1]),
        hasPrev: Boolean(contactRequests.value[openContactRequestIndex - 1]),
      }
    })
    const changeOpenContactRequest = dir => {
      const openContactRequestIndex = contactRequests.value.findIndex(e => e.id === contactRequestView.value.id)
      const newContactRequestIndex = dir === 'prev' ? openContactRequestIndex - 1 : openContactRequestIndex + 1
      contactRequestView.value = contactRequests.value[newContactRequestIndex]
    }

    // >>> View Actions
    const updateContactRequestView = contactRequest => {
      // contactRequest no se marca como leido
      contactRequestView.value = contactRequest
      isContactRequestViewOpen.value = true
    }

    const updateOpenContactRequestLabel = label => {
      selectedContactRequests.value = [contactRequestView.value.id]
      updateSelectedContactRequestLabelMultiple(label)

      // Update label in open contactRequest
      const labelIndex = contactRequestView.value.labels.indexOf(label)
      if (labelIndex === -1) contactRequestView.value.labels.push(label)
      else contactRequestView.value.labels.splice(labelIndex, 1)

      selectedContactRequests.value = []
    }

    // Actions: Publish, Finish
    const setOpenContactRequestAction = action => {
      selectedContactRequests.value = [contactRequestView.value.id]
      setSelectedContactRequestActionMultiple(action)

      selectedContactRequests.value = []
      isContactRequestViewOpen.value = false
    }

    // * If someone clicks on filter while viewing detail => Close the contactRequest detail view
    watch(routeParams, () => {
      isContactRequestViewOpen.value = false
    })

    // * Watcher to reset selectedContactRequests
    // ? You can also use isContactRequestViewOpen (instead of `contactRequestView`) but it will trigger execution twice in this case
    // eslint-disable-next-line no-use-before-define
    watch([contactRequestView, routeParams], () => {
      selectedContactRequests.value = []
    })

    // Delete with dialog ask
    const isContactRequestDeleteDialogActive = ref(false)
    const contactRequestDeleteId = ref('')
    const contactRequestDeleteDependenciesError = ref(null)
    const askContactRequestDelete = (contactRequestId, dempendenciesError = null) => {
      isContactRequestDeleteDialogActive.value = true
      contactRequestDeleteId.value = contactRequestId

      contactRequestDeleteDependenciesError.value = dempendenciesError
    }

    const onContactRequestDelete = (contactRequestId, force = false) => {
      console.log(force)
      useContactRequest
        .removeContactRequest(contactRequestId)
        .then(() => {
          // Unselect current selection
          selectedContactRequests.value = []
          isContactRequestViewOpen.value = false
          contactRequestView.value = {}

          sendContactRequestNotification('ContactRequest Deleted', 'success')
          fetchContactRequests()
        })
        .catch(error => {
          // Checking... Dependencies error
          // if (error.response && error.response.data && error.response.data.error) {
          if (error.response && error.response.data && error.response.data.error) {
            if (error.response.data.code === 'contact_request_inbound_dependencies_error') {
              // if (error.response.data.error.campaigns || error.response.data.error.contactRequests) {
              // Send dependencies
              //  campaigns: error.response.data.error.campaigns,
              //  contactRequests: error.response.data.error.contactRequests,
              askContactRequestDelete(contactRequestId, error.response.data.error)
            } else {
              sendContactRequestNotification(error.response.data.message, 'error')
            }
          }
        })
    }

    // ————————————————————————————————————
    //* ——— Perfect Scrollbar
    // ————————————————————————————————————
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      isLeftSidebarOpen,
      contentStyles,

      // Search Query
      searchQuery,

      // ContactRequests & ContactRequest Meta
      contactRequests,
      contactRequestsMeta,
      fetchContactRequests,
      loading,

      // Refresh and loadmore
      onRefresh,
      onLoad,
      finished,
      loadMoreError,
      refLoadMore, // Load more ref DOM (Scroll control)

      // ContactRequest Selection
      selectedContactRequests,
      toggleSelectedContactRequest,
      selectAllContactRequestCheckbox,
      isSelectAllContactRequestCheckboxIndeterminate,
      selectAllCheckboxUpdate,
      isAllSelectedContactRequestAreRead,

      // ContactRequest Actions
      toggleStarred,
      updateSelectedContactRequestLabelMultiple,
      deleteSelectedContactRequestMultiple,
      setSelectedContactRequestActionMultiple,

      // ContactRequest View
      isContactRequestViewOpen,
      contactRequestView,
      openContactRequestMeta,
      updateContactRequestView,
      updateOpenContactRequestLabel,
      changeOpenContactRequest,
      setOpenContactRequestAction,

      // useContactRequest
      resolveContactRequestLabelColor,
      resolveContactRequestStatus,
      customActionPublished,
      customActionFinished,
      updateLabelMenuListItems,
      CONTACT_REQUEST_STATUS,

      // useContactRequestInbound - Join status (ContactRequest + ContactRequestInbound)
      resolveContactRequestInboundStatus,
      CONTACT_REQUEST_INBOUND_STATUS,

      // CRUD Delete
      isContactRequestDeleteDialogActive,
      contactRequestDeleteId,
      contactRequestDeleteDependenciesError,
      askContactRequestDelete,
      onContactRequestDelete,

      // Perfect Scrollbar
      perfectScrollbarSettings,

      // Filter/Formatter
      avatarText,
      formatDateToMonthShort,

      // Icons
      icons: {
        mdiTune,
        mdiMenu,
        mdiMagnify,
        mdiChevronRight,
        mdiChevronLeft,
        mdiTrashCanOutline,
        mdiReload,
        mdiDotsVertical,
        mdiStarOutline,
        mdiStar,
        mdiFolderOutline,
        mdiEmailOutline,
        mdiEmailOpenOutline,
        mdiLabelOutline,
        mdiAttachment,
        mdiAlertOctagonOutline,
      },
    }
  },
}
</script>

<!-- LoadMore -->
<style lang="css">

  /* Para hacer scroll dentro del div (Usar si indicamos altura fija) */
  .vuejs-loadmore-wrap {
    overflow: scroll;
  }
  /* En escritorio deshabilita la parte superior (Unos 64px que son usados para arrastrar y soltar) */
  .vuejs-refresh-head {
    z-index: -10;
  }
  .vuejs-refresh-track {
  }
</style>

<style lang="scss">
  @import '~vuetify/src/styles/styles.sass';
  @import '~@core/preset/preset/mixins';

  // // .max-h-content-container {
  // //     height: calc((var(--vh, 1vh) * 100) - 64px - 2px -2px) !important; // 64 + 2 de borde + 2 ??
  // // }

  .app-contact-request {
    // Style search input
    .contact-request-search-input {
      .v-input__slot {
        fieldset {
          border: none !important;
        }
        input {
          caret-color: var(--v-primary-base) !important;
        }
      }
    }
    // @include theme(contact-request-search-input) using ($material) {
    //   .v-input__slot {
    //     input {
    //       caret-color: map-deep-get($material, 'text', 'primary') !important;
    //     }
    //   }
    // }
  }

  // // // Perfect Scroll
  // .ps-contact-request-list {
  //   // height: calc(100% - 64px - 56px - 46px); // 96+2 deben ser el footer que no lleva
  //   height: calc(100%);
  //   // height: calc((var(--vh, 1vh) * 100) - 64px - 56px -46px ) !important; // Navbar(64px) Searchbar(56px) Tablebar(46px)

  //   .contact-request-item {
  //     &.hovered {
  //       transform: translateY(-2px);
  //       transition: all 0.2s;
  //       z-index: 1;
  //     }
  //   }
  // }

  // @include theme--child(ps-contact-request-list) using ($material) {
  //   .contact-request-item {
  //     &:not(:last-child) {
  //       border-bottom: 1px solid map-deep-get($material, 'dividers');
  //     }
  //     &.contact-request-read {
  //       background-color: rgba(map-deep-get($material, 'primary-shade'), 0.04);
  //     }
  //   }
  // }
</style>
